

import { Vue, Component, Watch } from "vue-property-decorator";

import { TaskService } from "@/services/task";
import { EmployeeService } from "@/services/employee";
import { ReportService } from "@/services/report";

import { GroupDTO } from "@/services/task/task.dto";
import { EmployeeDTO } from "@/services/employee/employee.dto";

interface TableHeader {
  text: string;
  value: string;
}

interface Employee {
  name: string;
  employeeId: string;
  groupsAssigned?: number;
}

interface Group extends GroupDTO {
  index: number;
  employees: string;
  status: string;
  pending: number;
}

enum STATUS {
  COMPLETED = "Completed",
  ONGOING = "Ongoing",
  UNASSIGNED = "Unassigned"
}

const ALL_GROUPS = "All Groups";
const ASSIGNED_GROUPS = "Assigned Groups";
const UNASSIGNED_GROUPS = "Unassigned Groups";

@Component
export default class Groups extends Vue {
  Status = STATUS;
  isSelectAllChecked = false;
  isReassigning = false;
  loading = false;
  groups: Group[] = [];
  filteredGroups: Group[] = [];
  employees: Employee[] = [];
  selectedEmployee: Employee[] = [];
  selectedGroups: Group[] = [];
  file!: File;
  groupAssignmentStatusItems = [ALL_GROUPS, ASSIGNED_GROUPS, UNASSIGNED_GROUPS];
  groupAssignmentStatus = ALL_GROUPS;
  canAssign = false;
  canReassign = false;
  showAddCustomerDialog = false;
  showAssignReassignEmployeeDialog = false;
  searchTermForGroups = "";
  searchTermForEmployees = "";
  headersForCustomersTable: TableHeader[] = [
    {
      text: "",
      value: "status"
    },
    {
      text: "Group Name",
      value: "groupName"
    },
    {
      text: "Area",
      value: "area"
    },
    {
      text: "Pincode",
      value: "postalCode"
    },
    {
      text: "Total Readings",
      value: "total"
    },
    {
      text: "Completed Readings",
      value: "completed"
    },
    {
      text: "Pending Readings",
      value: "pending"
    },
    {
      text: "Door Locked",
      value: "doorLocked"
    },
    {
      text: "Employees",
      value: "employees"
    }
  ];
  headersForEmployeesTable: TableHeader[] = [
    {
      text: "Employee Name",
      value: "name"
    },
    {
      text: "Groups Assigned",
      value: "groupsAssigned"
    }
  ];

  data() {
    return {
      file: null,
      selectedGroup: [],
      selectedEmployee: [],
      showAddCustomerDialog: false,
      showAssignReassignEmployeeDialog: false,
      searchTermForGroups: "",
      searchTermForEmployees: ""
    };
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 3);
    this.$store.commit("liveData/setSelectedActivityName", "Customers");
    this.getGroups();
  }

  @Watch("isSelectAllChecked")
  onSelectAllCheckChange() {
    if (this.isSelectAllChecked) {
      this.selectedGroups = this.filteredGroups.map((group: Group) => group);
    } else {
      this.selectedGroups = [];
    }
  }

  @Watch("selectedGroups")
  onSelectedGroupsChange() {
    this.canAssign =
      this.selectedGroups.filter(
        (group: Group) =>
          group.completed === 0 && group.assignedEmployees.length === 0
      ).length === this.selectedGroups.length && this.selectedGroups.length > 0;

    this.canReassign =
      this.selectedGroups.filter(
        (group: Group) =>
          group.completed < group.total && group.assignedEmployees.length > 0
      ).length === this.selectedGroups.length && this.selectedGroups.length > 0;
  }

  async getGroups() {
    this.loading = true;
    let employeeIndex = -1;
    try {
      const groupDTOs: GroupDTO[] = await TaskService.getGroups();

      this.groups = groupDTOs.map((group: GroupDTO, index: number) => ({
        ...group,
        index,
        employees: group.assignedEmployees
          .map(employee => employee.name)
          .join(" "),
        pending: group.total - group.completed,
        status:
          group.total === group.completed
            ? this.Status.COMPLETED
            : group.assignedEmployees.length === 0
            ? this.Status.UNASSIGNED
            : this.Status.ONGOING
      }));

      this.filteredGroups = [...this.groups];

      for (const group of this.filteredGroups) {
        for (const assignedEmployee of group.assignedEmployees) {
          employeeIndex = this.employees.findIndex(
            (employee: Employee) =>
              employee.employeeId === assignedEmployee.employeeId
          );
          if (employeeIndex === -1) {
            this.employees.push({
              ...assignedEmployee,
              groupsAssigned: 1
            });
          } else {
            // eslint-disable-next-line
            //@ts-ignore
            this.employees[employeeIndex].groupsAssigned += 1;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
    this.getEmployees();
  }

  async getEmployees() {
    try {
      let employeeIndex = 0;
      const employeeDTOs: EmployeeDTO[] = await EmployeeService.getEmployees(
        true
      );
      for (const employeeDTO of employeeDTOs) {
        employeeIndex = this.employees.findIndex(
          (employee: Employee) => employee.employeeId === employeeDTO.employeeId
        );
        if (employeeIndex === -1) {
          this.employees.push({
            ...employeeDTO,
            groupsAssigned: 0
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  filterGroups() {
    if (this.groupAssignmentStatus === ALL_GROUPS) {
      this.filteredGroups = [...this.groups];
    } else if (this.groupAssignmentStatus === ASSIGNED_GROUPS) {
      this.filteredGroups = this.groups.filter(
        group => group.assignedEmployees.length > 0
      );
    } else if (this.groupAssignmentStatus === UNASSIGNED_GROUPS) {
      this.filteredGroups = this.groups.filter(
        group => group.assignedEmployees.length === 0
      );
    }
  }

  async assignReassignGroupToEmployee() {
    try {
      this.selectedGroups;
      const promises: Promise<void>[] = this.selectedGroups.map(group =>
        this.canAssign
          ? TaskService.assignGroup({
              ...group,
              employeeId: this.selectedEmployee[0].employeeId
            })
          : TaskService.reassignGroup({
              ...group,
              employeeId: this.selectedEmployee[0].employeeId
            })
      );
      await Promise.allSettled(promises);
      this.showAssignReassignEmployeeDialog = false;
      this.getGroups();
    } catch (err) {
      console.log(err);
    }
  }

  async parseExcel() {
    try {
      const formData = new FormData();
      formData.append("file", this.file);
      await ReportService.parseExcel(formData);
      this.showAddCustomerDialog = false;
      alert(
        "Report uploaded successfully. Please check the reports tab for status."
      );
    } catch (err) {
      console.log(err);
    }
  }

  goToGroupDetails(group: Group) {
    console.log(group);
    this.$router.push({
      path: "/customers",
      query: {
        groupName: group.groupName,
        area: group.area,
        postalCode: group.postalCode
      }
    });
  }

  async openAssignReassignEmployeeDialog() {
    this.showAssignReassignEmployeeDialog = true;
  }

  async closeAssignReassignEmployeeDialog() {
    this.showAssignReassignEmployeeDialog = false;
  }
}
